import axios, { AxiosError } from 'axios';
import { SpinnerArea } from '@/interface/common';
import { getAccessToken, redirectToLogin } from '@/index';
import { store } from '@/store';
import { changeSpinnerType } from '@/store/spinnerSlice';
import { noop } from 'lodash';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 100000,
});

export const onRejectedOfResponse = (error: AxiosError) => {
  if (error.config?.spinnerType !== SpinnerArea.None) {
    store.dispatch(changeSpinnerType(SpinnerArea.None));
  }
  let msg;
  let statusCode;
  const { response } = error;
  if (response && response instanceof Object) {
    const { data } = response;
    statusCode = response.status;

    if (statusCode === 401) {
      redirectToLogin().catch(noop);
    }

    if (data instanceof Object) {
      return Promise.reject({
        ...data,
        success: false,
      });
    }
    msg = data;
  } else {
    statusCode = 600;
    msg = error.message || 'Network Error';
  }

  return Promise.reject({ success: false, statusCode, message: msg, error });
};

const requestMap = new Map();

let abortList: string[] = []; // store last page or user changed the tab
let extraAbortList: string[] = []; // store building selector filter

export function getKey(config: any) {
  return config.method + config.url + (JSON.stringify(config.params) ?? '');
}
instance.interceptors.request.use(
  async (config: any) => {
    // Attention: if you set a request is partial loading, you must set the spinner type by yourself.
    const { spinnerType = SpinnerArea.Global } = config;
    if (config.spinnerType !== SpinnerArea.None) {
      store.dispatch(changeSpinnerType(spinnerType));
    }

    if (!config.signal) {
      const controller = new AbortController();
      const key = getKey(config);
      config.signal = controller.signal;
      if (requestMap.has(key)) {
        requestMap.get(key).abort();
        requestMap.delete(key);
      } else {
        requestMap.set(key, controller);
      }
    }

    if (config.abortToken) {
      const controller = new AbortController();
      config.signal = controller.signal;
      if (abortList.concat(extraAbortList).includes(config.abortToken)) {
        controller.abort();
      }
    }

    const access_token = await getAccessToken();
    if (access_token) {
      const contentType: { accept?: string; 'Content-Type'?: string } = {};
      if (config.url.includes('/core/v2')) {
        contentType.accept = 'application/vnd.api+json';
        contentType['Content-Type'] = 'application/vnd.api+json';
      }

      config = {
        ...config,
        headers: {
          ...(config.headers || {}),
          ...contentType,
          Authorization: `Bearer ${access_token}`,
        },
      };
    }

    return {
      ...config,
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use((response: any) => {
  if (response.config.spinnerType !== SpinnerArea.None) {
    store.dispatch(changeSpinnerType(SpinnerArea.None));
  }
  const key = getKey(response.config);
  requestMap.delete(key);
  return response.data;
}, onRejectedOfResponse);

const authRequest = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL,
  timeout: 100000,
});

const abortRequests = (abortTokens: string[]) => {
  abortList = abortTokens;
};

const abortExtraRequests = (abortTokens: string[]) => {
  extraAbortList = abortTokens;
};

const getAbortList = () => {
  return extraAbortList.concat(abortList);
};

export default instance;
export { authRequest, abortRequests, abortExtraRequests, getAbortList };
