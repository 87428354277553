import panelReducer from './panel';
import breakerReducer from './breakers';
import circuitReducer from './circuit';
import buildingReducer from './building';

export default {
  ...panelReducer,
  ...breakerReducer,
  ...circuitReducer,
  ...buildingReducer,
};
