/* eslint-disable no-prototype-builtins */
import { DocBreaker, DocCircuit, DocType } from '@/interface/docTypes';
import { Breaker, Chain, Circuit, DataGateway, PanelDetailType } from '@/interface/panelTypes';

function initDoc(panelInfo: PanelDetailType): DocType {
  const notes = null;
  const mains = panelInfo.panel.mains;

  let leftBreakers: {
    totalSensor: number;
    breakers: DocBreaker[];
  } = {
    totalSensor: 0,
    breakers: [],
  };
  let rightBreakers: {
    totalSensor: number;
    breakers: DocBreaker[];
  } = {
    totalSensor: 0,
    breakers: [],
  };
  let archiveBreakers: DocBreaker[] = [];
  let dataGateWay: DataGateway = { uid: '', chain1: [], chain2: [] };
  let availableLeftChannels: number[] = [];
  let availableRightChannels: number[] = [];

  const init = (panelInfo: PanelDetailType) => {
    // store data gateway
    dataGateWay = panelInfo['data-gateway'];
    leftBreakers = {
      totalSensor: panelInfo['data-gateway'].chain1.filter((channel) =>
        channel.hasOwnProperty('active') ? channel.active : true
      ).length,
      breakers: initBreaker(panelInfo['data-gateway'].chain1, panelInfo),
    };
    rightBreakers = {
      totalSensor: panelInfo['data-gateway'].chain2.filter((channel) =>
        channel.hasOwnProperty('active') ? channel.active : true
      ).length,
      breakers: initBreaker(panelInfo['data-gateway'].chain2, panelInfo),
    };
    archiveBreakers = initArchivedBreaker(panelInfo);
    const attachmentChannels = panelInfo.attachments.map((attach) => attach.channel);
    availableLeftChannels = panelInfo['data-gateway'].chain1
      .filter((channel) => (channel.hasOwnProperty('active') ? channel.active : true))
      .map((chainItem) => chainItem.channel)
      .filter((chainChannel) => !attachmentChannels.includes(chainChannel));
    availableRightChannels = panelInfo['data-gateway'].chain2
      .filter((channel) => (channel.hasOwnProperty('active') ? channel.active : true))
      .map((chainItem) => chainItem.channel)
      .filter((chainChannel) => !attachmentChannels.includes(chainChannel));
  };

  const hasBreakerCircuitsOnMultipleChains = (panelInfo: PanelDetailType): boolean => {
    const breakers = panelInfo.panel.breakers;
    return !breakers
      .map((breaker) => {
        return circuitInDiffChainCount(panelInfo, breaker) === 1;
      })
      .every((sameChain) => sameChain);
  };

  const initBreaker = (chainList: Chain[], panelInfo: PanelDetailType): DocBreaker[] => {
    const breakerList: DocBreaker[] = [];
    chainList.forEach((chain) => {
      // from channel get circuitId
      const circuitId = panelInfo.attachments.find((att) => att.channel === chain.channel)?.circuit;
      // from circuitId , find the breaker and circuit
      const breaker = panelInfo.panel.breakers.find(
        (breaker) =>
          circuitInDiffChainCount(panelInfo, breaker) === 1 &&
          breaker.circuits.findIndex((citc) => citc.id === circuitId) > -1
      );
      const circuit = breaker?.circuits.find((citc) => citc.id === circuitId);
      if (!breaker || !circuit) return;
      // push circuit to sortedBreaker
      const existBreakerIndex = breakerList.findIndex((brk) => brk.id === breaker.id);
      const newCircuit = {
        ...circuit,
        ...chain,
      };
      if (existBreakerIndex > -1) {
        breakerList[existBreakerIndex].circuits.push(newCircuit);
      } else {
        breakerList.push({
          ...breaker,
          circuits: [newCircuit],
        });
      }
    });
    return breakerList.map((breaker) => {
      return {
        ...breaker,
        endUseId: findPopularEndUseId(breaker.circuits),
      };
    });
  };

  const initArchivedBreaker = (panelInfo: PanelDetailType): DocBreaker[] => {
    // breaker's circuits can not find in attachment list,we call it archived
    const breakerList = panelInfo.panel.breakers.filter((breaker) => {
      return (
        circuitInDiffChainCount(panelInfo, breaker) > 1 ||
        breaker.circuits.every((circuit) => {
          return panelInfo.attachments.findIndex((att) => att.circuit === circuit.id) === -1;
        })
      );
    });
    return breakerList.map((breaker) => {
      return {
        ...breaker,
        endUseId: findPopularEndUseId(breaker.circuits),
        circuits: breaker.circuits.map((cir) => {
          return {
            ...cir,
            reverse: false,
          };
        }),
      };
    });
  };

  const findPopularEndUseId = (circuitList: DocCircuit[] | Circuit[]): number => {
    const counts: Record<string, number> = {};
    let maxCount = 0;
    let mostFrequentEndUseId = 0;

    for (const circuitItem of circuitList) {
      const endUserId = circuitItem.endUseId ?? 0;
      const mapKey = endUserId.toString();

      if (counts[mapKey]) {
        counts[mapKey]++;
      } else {
        counts[mapKey] = 1;
      }

      if (counts[mapKey] > maxCount && endUserId !== 0) {
        maxCount = counts[mapKey];
        mostFrequentEndUseId = endUserId;
      }
    }

    return mostFrequentEndUseId;
  };

  // init panel info
  const { id, name, phases, voltage, earthGroundedNeutral } = panelInfo.panel;
  const { isNoResource } = panelInfo;
  const version = panelInfo.version;
  init(panelInfo);

  return {
    id,
    name,
    phases,
    voltage,
    version,
    isNoResource: isNoResource || false,
    isCorrupted: hasBreakerCircuitsOnMultipleChains(panelInfo),
    earthGroundedNeutral,
    notes,
    mains,
    leftBreakers,
    rightBreakers,
    archiveBreakers,
    dataGateWay,
    availableLeftChannels,
    availableRightChannels,
  };
}

export // find how many chains does this breaker's circuits belongs
const circuitInDiffChainCount = (panelInfo: PanelDetailType, breaker: Breaker): number => {
  const chain1Channels = panelInfo['data-gateway'].chain1.map((chain) => chain.channel);
  const chain2Channels = panelInfo['data-gateway'].chain2.map((chain) => chain.channel);
  const breakerChannels = breaker.circuits.map((circuit) => {
    return panelInfo.attachments.find((cir) => cir.circuit === circuit.id)?.channel;
  });
  const breakerChains = breakerChannels.map((channel) => {
    if (!channel) return false;
    if (chain1Channels.includes(channel)) return 1;
    return chain2Channels.includes(channel) ? 2 : 3;
  });
  return new Set(breakerChains).size;
};
export default initDoc;
