import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { BuildingStateType } from '..';
import type { Building } from '@/interface/building';
import { Include } from '@/interface/include';

export default {
  saveBuildings: (state: WritableDraft<BuildingStateType>, action: PayloadAction<Building[]>) => {
    state.buildings = action.payload;
    state.buildingsBrief = action.payload
      .map((item) => ({
        value: item.id,
        label: item.attributes.name,
        slug: item.attributes.slug,
        accountId: item.relationships.account?.data.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  },
  updateBuilding: (state: WritableDraft<BuildingStateType>, action: PayloadAction<Building>) => {
    const index = state.buildings.findIndex((building) => building.id === action.payload.id);
    state.buildings[index] = action.payload;
  },
  saveIncludes: (state: WritableDraft<BuildingStateType>, action: PayloadAction<Include[]>) => {
    state.include = action.payload;
  },
};
