import { createSlice } from '@reduxjs/toolkit';
import { SpinnerArea } from '@/interface/common';

export interface SpinnerStateInterface {
  spinnerType: SpinnerArea;
  requestCount: number;
}

export const initialState: SpinnerStateInterface = {
  spinnerType: SpinnerArea.None,
  requestCount: 0,
};

const SPINNER_LEVEL = Object.values(SpinnerArea).slice(0, -1);
const spinnerSlice = createSlice({
  name: 'spinner',
  initialState,
  reducers: {
    changeSpinnerType: (state, action) => {
      if (action.payload === SpinnerArea.None) {
        state.requestCount--;
      } else {
        state.requestCount++;
      }

      // avoid to set spinnerType to None when requestCount > 0
      if (action.payload === SpinnerArea.None && state.requestCount > 0) {
        return;
      }

      // use the lowest level spinner
      if (
        action.payload !== SpinnerArea.None &&
        SPINNER_LEVEL.indexOf(action.payload) < SPINNER_LEVEL.indexOf(state.spinnerType)
      ) {
        return;
      }

      Object.assign(state, { spinnerType: action.payload });
    },
  },
});

export const { changeSpinnerType } = spinnerSlice.actions;
export default spinnerSlice.reducer;
