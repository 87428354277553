import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { BuildingStateType, defaultDoc } from '..';
import { cloneDeep } from 'lodash';
import { PanelHistoryItemType, PanelListType } from '@/interface/panelTypes';
import initDoc from '../initialize/doc';

export default {
  setExpandView: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<'' | 'history' | 'current'>
  ) => {
    state.expandedView = action.payload;
  },
  setHistoryList: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<PanelHistoryItemType[]>
  ) => {
    if (!action.payload.length) {
      state.historicalView = false;
      return;
    }
    if (state.historical) {
      state.historical.histories = action.payload;
      state.historical.currentVersion = action.payload[0].version;
      state.historyCxDoc = initDoc(action.payload[0].content);
      state.historicalView = true;
    }
  },
  setCurrentHistorical: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<number>
  ) => {
    const version = action.payload;
    const historyPanel = state.historical.histories.find((item) => item.version === version);
    state.historical.currentVersion = version;
    if (!historyPanel) {
      state.historyCxDoc = defaultDoc;
      return;
    }
    state.historyCxDoc = initDoc(historyPanel.content);
  },
  initPanelList: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<PanelListType[]>
  ) => {
    const panels = action.payload;
    panels.sort((a, b) => a.name.localeCompare(b.name));
    state.panelList = panels;
  },
  changeEditing: (state: WritableDraft<BuildingStateType>, action: PayloadAction<boolean>) => {
    state.editing = action.payload;
    const { currentPanel } = state;
    if (!action.payload && currentPanel) {
      state.cxDoc = initDoc(currentPanel);
    }
  },
  changeHistoricalView: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<boolean>
  ) => {
    state.historicalView = action.payload;
    if (!action.payload) {
      state.expandedView = 'current';
    }
  },
  updateCxDoc: (
    state: WritableDraft<BuildingStateType>,
    action: PayloadAction<{
      key: 'id' | 'name' | 'phases' | 'voltage';
      value: any;
    }>
  ) => {
    const cxDoc = cloneDeep(state.cxDoc);
    const { key, value } = action.payload;
    switch (key) {
      case 'id':
        cxDoc.id = value;
        break;
      case 'name':
        cxDoc.name = value;
        break;
      case 'phases':
        cxDoc.phases = value;
        break;
      case 'voltage':
        cxDoc.voltage = value;
        break;
      default:
        break;
    }
    state.cxDoc = cxDoc;
  },
};
