import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchStatus } from '@/interface/common';
import type { RootState } from './index';
import {
  DataValidationState,
  CircuitWithValidationList,
  ValidationPeriod,
} from '@/interface/dataValidation';

const initialState: DataValidationState = {
  panelValidationData: [],
  validationPeriod: {
    startTime: '',
    endTime: '',
  },
  status: FetchStatus.Loading,
};

const dataValidationSlice = createSlice({
  name: 'hierarchy',
  initialState,
  reducers: {
    setPanelPowerValidationData: (state, action: PayloadAction<CircuitWithValidationList[]>) => {
      state.panelValidationData = action.payload;
      state.status = FetchStatus.Idle;
    },
    setValidationPeriod: (state, action: PayloadAction<ValidationPeriod>) => {
      state.validationPeriod = action.payload;
    },
    updateValidationStatus: (state, action: PayloadAction<FetchStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { setPanelPowerValidationData, setValidationPeriod, updateValidationStatus } =
  dataValidationSlice.actions;
export const selectPanelPowerValidationData = (state: RootState) =>
  state.dataValidation.panelValidationData;
export const selectValidationPeriod = (state: RootState) => state.dataValidation.validationPeriod;
export const selectValidationStatus = (state: RootState) => state.dataValidation.status;
export default dataValidationSlice.reducer;
