import { lazy, ReactElement, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Loading from '@/components/loading';
import ErrorBoundary from '@/utils/errorBoundary';

interface Route {
  path?: string;
  element: (() => Promise<any>) | ReactElement;
  end?: boolean;
  children?: Route[];
}
interface RouteElement {
  routeElement: () => Promise<any>;
}

const routes: Array<Route> = [
  {
    path: '/',
    element: () => import('@/layout/layout'),
    children: [
      {
        path: '',
        element: <Navigate to="buildings" />,
      },
      {
        path: 'idm',
        element: () => import('@/pages/idm'),
      },
      {
        path: 'buildings',
        element: () => import('@/pages/panelConfiguration'),
      },
      {
        path: 'buildings/:buildingSlug/*',
        element: () => import('@/pages/panelConfiguration'),
      },
      {
        path: 'hierarchy',
        element: () => import('@/pages/panelHierarchy'),
      },
      {
        path: 'hierarchy/:buildingSlug/:buildingId?/:editModel?/*',
        element: () => import('@/pages/panelHierarchy'),
      },
      {
        path: 'account',
        element: () => import('@/pages/manageAccount'),
        children: [
          {
            path: '',
            element: <Navigate to="users" />,
          },
          {
            path: 'users',
            element: () => import('@/pages/manageAccount/users'),
          },
          {
            path: 'api-keys',
            element: () => import('@/pages/manageAccount/apiKeys'),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: () => import('@/pages/notFound'),
  },
];

function LazyElement({ routeElement }: Readonly<RouteElement>) {
  const LazyComponent = lazy(routeElement);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <LazyComponent />
      </Suspense>
    </ErrorBoundary>
  );
}

function wrapRoutesWithLazy(routes: Route[]) {
  routes.forEach((route) => {
    if (!(route.element instanceof Function)) {
      return;
    }
    const routeElement = route.element as () => Promise<any>;
    route.element = (<LazyElement routeElement={routeElement} />) as any;
    if (route.children) {
      wrapRoutesWithLazy(route.children);
    }
  });
}

wrapRoutesWithLazy(routes);

export default routes;
