import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import App from './App';
import { Auth0Provider, Auth0Context } from '@auth0/auth0-react';
import auth from '@/auth_config.json';
import { store } from '@/store';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const deferred = (() => {
  const props: any = {};
  props.promise = new Promise((resolve) => (props.resolve = resolve));
  return props;
})();

export const getAccessToken = async () => {
  const getToken = await deferred.promise;
  return getToken();
};

let toLogin: () => Promise<void>;
export const redirectToLogin = () => toLogin();

const providerConfig = {
  domain: auth.domain,
  clientId: auth.clientId,
  audience: auth.audience,
  redirectUri: window.location.origin,
  scope: auth.scope,
  onRedirectCallback: (appState: any) => {
    window.location.href = `${window.location.origin}${appState?.targetUrl || ''}`;
  },
};

const root = document.getElementById('root') as HTMLElement;
if (root) {
  ReactDOM.render(
    <Auth0Provider {...providerConfig}>
      <Auth0Context.Consumer>
        {({ getAccessTokenSilently, loginWithRedirect }) => {
          toLogin = loginWithRedirect;
          deferred.resolve(getAccessTokenSilently);
          return (
            <Provider store={store}>
              <App />
            </Provider>
          );
        }}
      </Auth0Context.Consumer>
    </Auth0Provider>,
    root
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
