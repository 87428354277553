import { Component, ReactNode } from 'react';

interface PropsTypes {
  children?: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<PropsTypes, ErrorBoundaryState> {
  state = { hasError: false };

  public static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="prose max-w-none h-full">
          <h2 className="text-center mt-56">Sorry... something went wrong.</h2>
        </div>
      );
    }

    return this.props.children;
  }
}
