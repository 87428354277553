import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AuthUser, UserRedux } from '@/interface/user';
import type { RootState } from './index';

export interface UserStateInterface {
  authUser: AuthUser;
  currentUser: UserRedux | undefined;
  // role level should be one of 2 | 3 | 4.
  currentUserRoleLevel: number;
  currentAccount: string;
  defaultBuildingId: string;
  users: UserRedux[];
}

export const initialState: UserStateInterface = {
  authUser: {
    given_name: '',
    family_name: '',
    nickname: '',
    name: '',
    picture: '',
    updated_at: '',
    email: '',
    email_verified: false,
    sub: '',
  },
  currentAccount: '',
  currentUser: undefined,
  currentUserRoleLevel: 4,
  defaultBuildingId: '',
  users: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveAuthUser: (state, action: PayloadAction<AuthUser>) => {
      state.authUser = action.payload;
    },
    saveUsers: (state, action: PayloadAction<{ users: UserRedux[]; setAccount?: string }>) => {
      state.users = [...action.payload.users].sort((a, b) =>
        a.attributes.name.localeCompare(b.attributes.name)
      );
      const currentUserId = state.authUser.sub?.split('|')[1];
      state.currentUser = action.payload.users.find((user) => user.id === currentUserId);
      state.currentUserRoleLevel = Number(state.currentUser?.relationships.roles.data.id ?? 4);
      // if url contains account, Use url's, not use default
      if (!action.payload.setAccount) {
        state.currentAccount = state.currentUser?.relationships.accounts.data[0].id ?? '';
        if (state.currentUser && !state.defaultBuildingId) {
          const defaultId =
            state.currentUser?.relationships['default-building'].data?.id ??
            state.currentUser?.relationships.buildings.data[0].id;
          state.defaultBuildingId = defaultId;
        }
      } else {
        state.currentAccount = action.payload.setAccount;
      }
    },
    setDefaultBuilding: (state, action: PayloadAction<string>) => {
      state.defaultBuildingId = action.payload;
    },
    setUserAccount: (state, action: PayloadAction<string>) => {
      state.currentAccount = action.payload;
    },
    addUser: (state, action: PayloadAction<UserRedux>) => {
      state.users.unshift(action.payload);
    },
    updateUser: (state, action: PayloadAction<UserRedux>) => {
      const userIndex = state.users.findIndex((user) => user.id === action.payload.id);
      state.users[userIndex] = action.payload;
    },
    deleteOneUser: (state, action: PayloadAction<number>) => {
      state.users.splice(action.payload, 1);
    },
  },
});

export const {
  saveAuthUser,
  saveUsers,
  setDefaultBuilding,
  setUserAccount,
  addUser,
  updateUser,
  deleteOneUser,
} = userSlice.actions;
export const selectAuthUser = (state: RootState) => state.user.authUser;
export const selectCurrentAccount = (state: RootState) => state.user.currentAccount;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectUsers = (state: RootState) => state.user.users;
export const selectCurrentUserRoleLevel = (state: RootState) => state.user.currentUserRoleLevel;
export const selectDefaultBuildingId = (state: RootState) => state.user.defaultBuildingId;
export default userSlice.reducer;
