import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Account } from '@/interface/account';
import type { RootState } from './index';

export interface AccountStateInterface {
  accounts: Account[];
  activeAccountId: number;
  accountsBrief: { label: string; value: string }[];
}

export const initialState: AccountStateInterface = {
  accounts: [],
  activeAccountId: 0,
  accountsBrief: [],
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    saveAccounts: (state, action: PayloadAction<Account[]>) => {
      state.accounts = [...action.payload].sort((a, b) =>
        a.attributes.name.localeCompare(b.attributes.name)
      );
      state.accountsBrief = state.accounts.map((item) => ({
        value: item.id,
        label: item.attributes.name,
      }));
    },
  },
});

export const { saveAccounts } = accountSlice.actions;
export const selectAccounts = (state: RootState) => state.account.accounts;
export const selectAccountsBrief = (state: RootState) => state.account.accountsBrief;
export default accountSlice.reducer;
