import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { Role, RoleName } from '@/interface/role';
import type { RootState } from './index';

export interface RoleStateInterface {
  roles: Role[];
  rolesName: RoleName[];
  rolesBrief: { label: string; value: string }[];
}

export const ROLES_NAME: RoleName[] = [
  undefined,
  'Verdigris Employee',
  'Account Owner',
  'Building Admin',
  'Building User',
];
export const initialState: RoleStateInterface = {
  rolesName: ROLES_NAME,
  roles: [],
  rolesBrief: [],
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    saveRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
      state.rolesBrief = action.payload.map((item) => ({
        value: item.id,
        label: item.attributes.name,
      }));
    },
  },
});

export const { saveRoles } = roleSlice.actions;
export const selectRoles = (state: RootState) => state.role.roles;
export const selectRolesName = (state: RootState) => state.role.rolesName;
export const selectRolesBrief = (state: RootState) => state.role.rolesBrief;
export default roleSlice.reducer;
